.app-container.iframe {
  .container-fluid{
    margin-top:0px;
    margin-bottom:0px;
    width: 100%;
    padding-bottom: 10px;
  }
  .caritaStyle li img {
    cursor: pointer;
    display: block;
    width: 4.3em;
  }

  .caritaStyle {
    height: auto;
    li{
      .labels{
        font-size: .5rem;
      }

    }
    >.icon{
      font-size: 3.3em;
    }
  }

  .caritaStyle.count-5 > li img {
    width: calc(var(--smile-height) - 2em) !important;
    margin: 0 auto;
  }

  .caritaStyle.count-5 > li img.select {
    width: calc(var(--smile-height) - 1.3em) !important;
    margin: 0 auto;
  }

  .caritaStyle.count-7 > li img {
    width: calc(var(--smile-height) - 3em) !important;
    margin: 0 auto;
  }

  .caritaStyle.count-7 > li img.select {
    width: calc(var(--smile-height) - 2.3em)  !important;
    margin: 0 auto;
  }
  .motivo-box{
    width: 100%;
  }
  .motivo-box{
    width: 100%;
  }

  .score_smiles h3.pregunta {
    padding:0px;
  }

  .pregunta {
    font-size: 21px;
  }

  .question_agrupada .pregunta {
    font-size: 11px;
  }

  .preguntaComentario{
    text-align: center;
    width: 100%;
    margin: auto;
    display: block;
    padding:0px;
  }

  .input{
    min-width: 100%;
  }
  .infoFields{
    font-size:15px;
  }

  .bottom-bar{
    >.inside-bottom-bar{
      margin:10px 6px 15px 6px;
    }
  }

  .lineas{
    width: 80%;
    margin:auto;
    // position: relative;
    // bottom: 15px;
  }
  .linea{
    height: 2px;
    background-color: lightgray;
  }
  .linea.hecha {
    background-color: #4e9b95;
  }
  .boton {
    margin:0px;
  }
  .tabla.default {
    display: block;
    width: 90% !important;
    margin: auto;
}

  .thanks-body{

    >h3{
      font-size:21px;
      line-height: 20px;
    }
    .thanksTitle{
      font-size: 23px;
      margin-top: 10px;
    }
  }
  .logo-container{

    >.logo{
      height: 65px;
    }

  }

  .motivos-checklist {
    width: unset;
    padding-left: unset;
    padding:5px;
    margin-bottom: 0px;
    justify-content: center;

  }

  .motivo-checklist {

    >div {
      margin-right: 7px;
    }
  }

  h3.pregunta {
    line-height: 25px;
    margin-bottom: 22px;
  }

  .inputMotive {
    min-width: 90% !important;
    margin:  0 auto;
   //display: inline-block;
  }
  .csatInput.box,
  .cesInput.box{
    width: 90% !important;
  }
  .motivo-box{
    width: 260px;
  }
  .score_csat{
    margin-bottom: 0;

  }

  @media (max-width: 300px) {
    .n-a li img {
      width: 3.6em;
    }
  }
}
