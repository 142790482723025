@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@600;700&display=swap');

.app-container {

  &.theme-width {
    --container-width: 90% !important;

    .inside {
      display: flex !important;
    }

    .header-img{
      max-width: 600px !important;
    }

    .userDataForm {
      width: 45% !important;
    }

    #presentation{
      width:50% !important;
    }

    @media (max-width: 900px) {
      --container-width: 600px !important;
      .inside {
        display: block !important;
      }
      .userDataForm {
        width: 100% !important;
      }

      #presentation{
        width:100% !important;
      }
    }

  }

  &.theme-no-card {
    --container-shadow: none;
    --container-width: 600px;
    --container-min-height: 700px;

    .tabla {
      &.default{
        display: block;
        margin:auto;
        @media (min-width: 425px) {
          width: 396px;
        }
      }
      &.image{
        display: block;
        margin:auto;
      }
    }
    .inputMotive {
      min-width: 20rem;
    }

    .caritaStyle.count-5 > li img {
      width: var(--smile-height);
      margin: 0 auto;
    }

    .caritaStyle.count-5 > li img.select {
      width: calc(var(--smile-height) + 1em);
      margin: 0 auto;
    }
  }

  &.theme-minimal {
    --flex-grow:unset;
    --flex-center:center;

    .motivos-checklist {
      padding-left: 0px;
      justify-content: var(--questions-general-alignment, center);
      margin: 0 auto;

    }
    .inputStyle {
      padding-left: 0px;
      justify-content: center;
    }

    .input{
      background-color: var(--input-bg-color, rgb(221, 221, 221));
      // border:solid 2px var(--response-selected-color);
    }
    .input2{
      background-color: var(--input-bg-color, rgb(221, 221, 221));
      // border:solid 2px var(--response-selected-color);
    }

    // dont Show logo
    .container {
      .principal {
        .pregunta {
          .imagen {
            display: none;
          }
        }
      }
    }

  }

  &.theme-dark {
    --background-color: black;
  }

  &.theme-white-comments {
    .inputMotive {
      background-color: white;
      border: solid var(--response-selected-color) 1px;
    }
    .pregunta {
      >.input {
        background-color: white;
        border: solid var(--response-selected-color) 1px;
      }
    }
  }

  &.theme-white-borders {
    //motive input
    .inputMotive {
      background-color: var(--background-color);
      border: solid var(--response-selected-color) 1px;
      border-radius: 5px;
    }
    //comments
    .pregunta {

      >.input {
        background-color: var(--background-color);
        border: solid var(--response-selected-color) 1px;
        border-radius: 5px;
      }
    }


    //for new theme
    //csat
    .csatInput {
      &.box {
        div{
          border:  var(--response-selected-color)1px solid;
        }
        div.select{
          border-color: var(--response-selected-color);
          background-color: var(--response-selected-color);
          color:white;
        }
      }
    }

    //ces
    .cesInput {
      &.box {
        div{
          border:var(--response-selected-color)1px solid;
          li {
            display: none;
          }
        }
        div.select{
          border-color: var(--response-selected-color);
          background-color: var(--response-selected-color);
          color:white;
        }
      }
    }
    //motivo
    .motivo-box{
      border-color: var(--response-selected-color);
    }
    .motivos{
      .motivo{
        border-color: var(--response-selected-color);
        border-radius: 5px;
        width: 100%;
        @media (min-width: 319px) {
          width: 140px;
        }
      }
    }
    //end new styles
  }
}

.dark-theme {
  background-color: #1A1A1A;
  .numericStyle {
    color: white;
  }
  .footer-text-container {
    > h5 {
      color: white;
    }
    > a {
      color: white;
    }
  }
  .motivo {
    border-color: white;
    color: white;
  }
  .csatInput {
    color: white;
  }
  .cesInput {
    color: white;
  }
  .motivo-checklist {
    color: white;
  }
  .matrix-table {
    color: white;
  }
  .preguntaAgrupadaInputStyle {
    color: white;
  }
  .sinselect {
    border-color: white !important;
  }
  .MuiInputLabel-root {
    color: white;
  }

  .matrix-column-name {
    color: white;
  }
  .csatInput.box div {
    border-color: white;
  }
  .cesInput.box div {
    border-color: white;
  }
  .caritaStyle.count-4 .labels {
    color: white;
  }
  .caritaStyle li .labels {
    color: white;
  }
  .label-score {
    color: white;
  }
  .presentation-text {
    color: white;
  }
}