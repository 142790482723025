@font-face {
  font-family: 'Gotham Medium';
  src: url('../GothamMedium.otf');
}

@font-face {
  font-family: 'Gotham Rounded Bold';
  src: url('../GothamRounded-Bold.otf');
}

@font-face {
  font-family: 'FS Joey Bold';
  src: url('../FS_Joey-Medium.otf');
}

@font-face {
  font-family: 'FS Joey';
  src: url('../FS_Joey.otf');
}

@font-face {
  font-family: 'Roboto';
  src: url('../Roboto-Regular.ttf');
}

.scroll-container {
  overflow: auto;
}

.ant-rate-star-second {
  color: #e6e6e6;
  transition: all 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.app-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  // Theme Defaults
  --container-shadow: 0px 0px 10px grey;
  --container-min-height: 700px;
  --container-min-width: 356px;
  --container-width: 396px;
  --smile-height: 5em;

  --opacity: 0;
  --opacity-color: 255, 255, 255;
  --container-background-color: transparent;

  --font-family-questions: 'Gotham Medium';
  --font-family-text: 'Gotham Medium';

  --flex-grow: 1;
  --flex-center: unset;

  --arrow-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.4);

  // Thanks Defaults
  --infoThanks-color: black;
  --icon-color: #d3d3d3;
  --icon-color-hover: #3296c8;
  --icon-size: 45px;
  --detailsThanksColor: black;
  --marginTopThanksTitle: 100px;
  --marginBottomThanksBody: 30px;

  * {
    box-sizing: border-box;
  }

  background-color: rgba(var(--opacity-color), var(--opacity));

  .MuiSelect-selectMenu {
    height: auto;
    overflow: hidden;
    min-height: 1.1876em;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .embed-player {
    width: -webkit-fill-available;
  }

  .title {
    font-family: var(--font-family-questions);
    color: var(--question-color);
  }

  .subtitle {
    font-family: var(--font-family-text);
    color: var(--response-color);
  }

  .footer {
    font-family: var(--font-family-text);
    color: var(--response-color);
  }

  .MuiListItem-button {
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .MuiListItem-root {
    width: 100%;
  }

  .MuiButtonBase-root {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 0;
    vertical-align: middle;
    text-decoration: none;
    padding: 8px 16px;
  }

  .MuiPaper-elevation8 {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
  }

  .MuiList-root {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
  }

  .MuiPaper-root {
    background-color: #fff !important;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 5px;
  }

  p {
    // display: contents;
  }

  a {
    text-decoration: blink;
    color: lightgray;
  }

  .btn-primary {
    background: var(--button-send-background-color);
    border-color: var(--button-send-background-color);

    &:hover {
      // background: #277973;
      // border-color: #277973;
      background: var(--button-send-background-color);
      border-color: var(--button-send-background-color);
      opacity: 0.8;
      box-shadow: 0px 0px 10px grey;
    }

    &:active {
      background: var(--button-send-background-color) !important;
      border-color: var(--button-send-background-color) !important;
      opacity: 0.9;
      // background: #246e69 !important;
      // border-color:#246e69  !important;
    }
  }

  .inside {
    flex-grow: var(--flex-grow);
    //display: flex;
    align-items: center;
    justify-content: center;
  }

  .bottom-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    bottom: 15px;
    width: 100%;
    padding: 6px;

    >.inside-bottom-bar {
      margin: 10px 6px;
      display: flex;
      justify-content: space-between;

      >.ant-row {
        border: 2px black solid;

        >.ant-col {
          border: 1px red solid;
        }
      }
    }
  }

  .lineas {
    display: flex;
    justify-content: center;
  }

  .linea {
    height: 6px;
    width: 100%;
    background-color: darkgray;
    border-radius: 17px;
    margin: 0px;
    margin: 0 5px;
    border-style: none;
  }

  .linea.hecha {
    background-color: dimgrey;
  }

  .container-fluid {
    cursor: default;
    background: white;
    text-align: center;
    margin: 10px;
    border-radius: 10px;
    padding-bottom: 20px;
    position: relative;
  }

  .texto {
    line-height: 40px;
    color: black;
    // font-family: Arial;
    font-family: var(--font-family-text);
    font-size: x-large;
    font-weight: normal;
  }

  .thanksTitle {
    // color:var(--detailsThanksColor);
    // font-size: var(--thanksSubtitleSize);
    font-weight: normal;
    margin-top: var(--marginTopThanksTitle);
    color: var(--response-color);
    white-space: pre-line;
  }

  .thanksSubtitle {
    // color:var(--detailsThanksColor);
    font-size: var(--thanksSubtitleSize);
    font-weight: normal;
    color: var(--response-color);
    white-space: pre-line;
  }

  .poweredSmile {
    width: 120px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 30px;
    text-align: center;
  }

  .redes {
    height: 20px;
    width: 20px;
    margin-right: 3px;
  }

  .bigRedes {
    /* margin-bottom: -4px; */
    height: 50px;
    width: 50px;
    /* margin-right: 3px; */
    margin: auto;
    color: #d3d4d9;
  }

  .multiplesRedes {
    display: flex;
    justify-content: center;
    font-size: 22px;
    // font-family: 'Arial';
    font-family: var(--font-family-text);
    bottom: 100px;
  }

  .infoThanks {
    display: flex;
    justify-content: center;
    font-size: 18px;
    bottom: 180px;
    color: var(--infoThanks-color);
    // font-family: 'Arial';
    font-family: var(--font-family-text);
  }

  .spanIcon {
    color: var(--icon-color);
  }

  .logo {
    max-width: 240px;
    max-height: 100px;
    writing-mode:vertical-lr;
  }

  .icon-number {
    background: var(--icon-number-background);
  }

  .icon {
    font-size: var(--icon-size);
    color: var(--icon-color);
  }

  .littleIcon {
    font-size: 20px;
    color: var(--icon-color);
  }

  .principal {
    justify-content: center;
    // display: grid;
  }

  .publicidad {
    color: darkgrey;
  }

  .imagen {
    max-width: 240px;
    max-height: 100px;
  }

  .preguntaComentario {
    margin: 10px 0;
    padding-top: 20px;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    text-align: var(--questions-general-alignment, center);
  }

  .tabla {
    display: inline;
    align-self: center;
  }

  .motivos {
    padding-top: 20px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }

  .motivo {
    cursor: pointer;
    outline: none;
    line-height: 15px;
    border-radius: 10px;
    background-color: transparent;
    color: var(--response-color);
    border-color: var(--response-color);
    //min-width: 100px;
    width: 137px;
    border-width: 1px;
    border-style: double;
    min-height: 42px;
    font-family: var(--font-family-text);
    vertical-align: middle;
    padding: 1rem;
    // margin: 3px 3px;
  }

  .motivoMetadata {
    border-radius: 16px;
    color: var(--response-color);
    background-color: transparent;
    border-color: black;
    min-width: 137px;
    max-width: 137px;
    border-width: 1px;
    border-style: double;
    height: 42px;
    font-family: var(--font-family-text);
    margin: 3px 3px;
    outline: none;
    cursor: pointer;
    max-width: 137px;
    border-width: 1px;
    border-style: double;
    height: 42px;
    margin: 3px 3px;
    outline: none;
    cursor: pointer;
  }

  .motivos-checklist {
    display: grid;
    width: 90%;
    padding-left: 40px;
    margin-bottom: 20px;
    font-size: 16px;
    justify-content: var(--questions-general-alignment, center);
  }
  .blank-space {
    height: 50px;
    &.box {
      display: none;
    }
    &.image {
      display: none;
    }
  }

  .comment-length {
    text-align: end;
    font-size: 15px;
  }

  .motivo-checklist {
    color: var(--response-color);
    cursor: pointer;
    display: flex;
    line-height: 25px;
    margin: 5px;
    font-family: var(--font-family-text);
    text-align: left;
    background: transparent;

    >div {
      margin-right: 3px;
    }
  }

  .motivo-checklist.selected {
    color: var(--response-selected-color);
  }

  .motivos-box {
    display: grid;
    width: 100%;
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 17px;
    justify-content: var(--questions-general-alignment, center);
    // margin-bottom: 20px;
  }

  .motivos-image {
    padding-top: 20px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: var(--questions-general-alignment, center);
  }

  .motivo-image {
    cursor: pointer;
    outline: none;
    line-height: 15px;
    border-radius: 10px;
    background-color: #cacaca2e;
    color: var(--response-color);
    width: min-content;
    margin: 4px;
    font-family: var(--font-family-text);
    vertical-align: middle;
    padding: 10px;

    >.not-show {
      display: none;
    }
  }

  .image-container {
    width: 200px;
    height: 200px;
    -webkit-writing-mode: vertical-lr;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .custom-image-container {
    width: 100%;
    height: 100%;
  }

  .motivo-image.selected {
    border-color: var(--response-selected-color);
    background-color: var(--response-selected-color);
    color: white;
  }

  .motivo-box {
    width: 20rem;
    color: var(--response-color);
    cursor: pointer;
    display: flex;
    line-height: 15px;
    font-family: var(--font-family-text);
    text-align: left;
    padding: 0.5rem;
    border: var(--response-color) 1px solid;
    justify-self: center;
    border-radius: 5px;
    margin: 2px;
    margin-bottom: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 5px;
    background: #fff;

    >div {
      display: none;
    }
  }

  .motivo-box.selected {
    border-color: var(--response-selected-color);
    background-color: var(--response-selected-color);
    color: white;
  }

  .motivo.selected {
    background-color: var(--response-selected-color);
    color: white;
    border-color: var(--response-selected-color);
  }

  .motives-max-quantity {
    color: var(--question-color);
    // max-width: '410px';
    // font-size: 18px;
    margin-bottom: 10px;
    font-family: var(--font-family-text);
  }

  b {
    font-size: larger;
  }

  .pregunta {
    color: var(--question-color);
    margin: 10px 0;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    font-size: 22px;
    font-family: var(--font-family-questions);
    padding: 0px 10px 0px 10px;
    white-space: pre-line;
    line-height: 1.85rem;
    justify-content: var(--questions-general-alignment, center);
    text-align: var(--questions-general-alignment, center);

    // color:black;
    .caja {
      margin-top: 14px;
    }

    .subpregunta {
      font-size: 18px;
    }
  }

  .pregunta.caja {
    justify-content: center;
  }

  .logo-container {
    justify-content: var(--logo-alignment, center);
  }

  .pre-footer {
    display: block !important;
    justify-content: space-between;
  }

  .userDataField {
    margin: 10px 0;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .score_smiles {
    display: flex;
    flex-direction: column;

    h3.pregunta {
      margin-top: 20px;
      margin-bottom: 22px;
      padding-left: 13px;
      padding-right: 13px;
      white-space: pre-line;
    }

    .pregunta {
      margin-top: 0;
      margin-bottom: 22px;
      padding-left: 13px;
      padding-right: 13px;
      white-space: pre-line;
    }
  }

  .infoFields {
    // max-width: 248px;
    width: 95%;
    font-size: 18px;
    color: var(--response-color);
    /* min-width: 29px; */
    display: inline-block;
    /* text-align: center; */
    justify-content: center;
    font-family: var(--font-family-questions);
    margin-top: 9px;
    margin-bottom: 8px;
  }

  label {
    margin-bottom: 14px;
  }

  .boton {
    outline: none;
    padding: 6px;
    border-radius: 16px;
    border-color: black;
    min-width: 108px;
    max-width: 125px;
    border-width: 1px;
    border-style: double;
    font-family: var(--font-family-text);
    height: 42px;
    // margin-bottom: 19px;
    // margin-top: 18px;
    color: var(--button-send-font-color);
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
  }

  .thin-button {
    font-weight: 400;
    font-size: 1rem;
    border-radius: 10px;
    height: 30px;
    padding: 10px 20px 25px;
  }

  .upload-image {
    margin-top: 100px;
  }

  .upload-image-iframe {
    margin-top: 10px;
  }

  .container-alert {
    width: 80%;
    margin: auto;
    margin-top: 50px;
  }

  .container-alert-iframe {
    margin-top: 10px;
  }

  .error {
    color: red;
  }

  .matrixQuestion {
    color: var(--question-color);
    font-family: var(--font-family-text);
  }

  .input2 {
    width: 90%;
    height: 30px;
    padding-left: 6px;
    background-color: var(--input-bg-color, lightgray);
    border-radius: 16px;
    border-color: var(--input-bg-color, lightgray);
    margin-top: 9px;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--response-color);
    border: antiquewhite;
    resize: none;
    outline: none;
  }

  .input {
    outline: none;
    resize: none;
    border-radius: 16px;
    height: 100px;
    width: 90%;
    background-color: var(--input-bg-color, lightgray);
    display: flex;
    border-color: var(--input-bg-color, lightgray);
    font-size: 1rem;
    line-height: 1.5;
    color: var(--response-color);
    border: rgb(56, 55, 53);
    padding: 10px;
    min-width: 20rem;
    /*@media (min-width: 768px) {
      min-width: 35rem;
    }*/
  }

  .matrix-table-container tbody tr {
    background-color: var(--matrix-question-bg-color, var(--container-background-color));
    border: solid var(--matrix-question-border-color, var(--container-background-color)) 1px;
  }

  .inputMotive {
    outline: 0;
    border: none;
    margin-top: 2px;
    //width: 80%;
    //margin-left:40px;
    margin-bottom: 20px;
    font-family: var(--font-family-text);
    border-radius: 5px;
    padding: 8px 0px 8px 10px;
    line-height: 15px;
    background-color: var(--input-bg-color, #dddddd);
    color: var(--response-color);
    display: block;
    margin: 0 auto;
    min-width: 20rem;
    margin-top: 2px;

    &.checklist{
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
    }

    &.box {
      display: inline-block;
      margin-left: 0px;
      width: 50%;
      height: 35px;
      margin-bottom: 10px;
    }

    &.image {
      width: -webkit-fill-available;
      min-width: unset !important;
    }
  }

  .caritaStyle {
    // cursor: pointer;
    list-style: none;
    //height: 80px;
    margin-top: -3px;
    margin-bottom: 2rem;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 0;
    display: flex;
    align-items: top;
    justify-content: var(--questions-general-alignment, center);
    // margin-right: 9px;

    .icon {
      cursor: pointer;
      font-size: var(--smile-height);
      margin: 0px 2px;
      color: #bdbdbd;
      -webkit-tap-highlight-color: transparent;

      &.select {
        color: var(--response-selected-color);
      }

      &:hover {
        color: var(--response-selected-color);
        transition-delay: 0s;
      }
    }
  }

  // .sinselect {
  //   filter: unquote("grayscale()");
  // }

  .sinselect-gray {
    filter: unquote('grayscale()');
  }

  .numeric-container {
    width: 70px;
    height: 70px;
    border-radius: 100px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    h4 {
      color: white;
      width: 100%;
      margin: 0;
    }
  }

  .numeric-container-7 {
    width: 45px;
    height: 45px;
  }

  @media (max-width: 410px) {
    .numeric-container {
      width: 45px;
      height: 45px;
      h4 {
        font-weight: 1rem !important;
      }
    }
  }

  @media (max-width: 390px) {
    .numeric-container-7 {
      width: 30px;
      height: 30px;
    }
    .font-ces-7 {
      font-size: 1.5rem !important;
    }

    .caritaStyle.count-7 {
      width: 300px;
      margin: auto;
    }
  }

  .caritaStyle.csat, .caritaStyle.ces {
    justify-content: center;
  }

  .caritaStyle li {
    float: left;
    margin: 0 5px;
    text-align: center;

    .labels {
      font-size: 0.7rem;
      line-height: 1;
      color: var(--response-color);
    }
  }

  .caritaStyle li img {
    cursor: pointer;
    display: block;
    width: var(--smile-height);
    padding-bottom: 10px;

    .labels {
      font-size: 0.7rem;
      line-height: 1;
      color: var(--response-color);
    }
  }

  .caritaStyle.count-4 {
    .labels {
      font-size: 0.7rem;
      line-height: 1;
      color: var(--response-color);
    }
  }

  .caritaStyle li img.select {
    width: 6em;
  }

  .caritaStyle.count-5>li img {
    width: var(--smile-height);
    margin: 0 auto;
  }


  .caritaStyle.count-5>li img.select {
    width: var(--smile-height);
    margin: 0 auto;
  }

  .caritaStyle.count-7>li img {
    width: calc(var(--smile-height) - 2em);
    margin: 0 auto;
  }

  .caritaStyle.count-7>li img.select {
    width: calc(var(--smile-height) - 1em);
    margin: 0 auto;
  }

  h4.dblock {
    display: block;
    text-align: center;
  }

  /* pregunta agrupada */
  .principalAgrupada {
    display: grid;
    padding: 0px 10px 0px 10px;
    margin-bottom: 15px;
    white-space: pre-line;
  }

  .matrix-table {
    width: auto;
    min-width: 100%;
    text-align: left;
  }

  .matrix-table td {
    padding: 10px !important;
  }

  .inputStyle {
    color: var(--response-color);
    list-style: none;
    padding-left: 43px;
    display: grid;
    align-items: center;
    justify-content: left;
    font-family: var(--font-family-text);
    font-size: 16px;

    >div {
      text-align: left;
    }

    >.select {
      color: var(--response-selected-color);
    }

    li {
      margin: auto;
      cursor: pointer;

      img {
        width: 1.5em;
      }

      div.select {
        width: 20px;
        height: 20px;
        background: var(--response-selected-color);
        color: var(--response-selected-color);
        border-radius: 100%;
        /* border: 1px solid #ddd; */
        border-style: double;
        border-color: white;
        transition: linear 0.4s;
      }

      div.sinselect {
        width: 20px;
        height: 20px;
        color: white;
        border: 1px solid var(--response-color);
        border-radius: 100%;
      }

      div.estadoInicial {
        width: 20px;
        height: 20px;
        border: 1px solid var(--response-color);
        border-radius: 100%;
      }
    }

    &.box {
      display: block;
      width: 70%;
      margin: auto;
      padding: 0px;

      div {
        padding: 5px 0px 5px 10px;
        margin: 5px;
        border: var(--response-color) 1px solid;
        border-radius: 5px;

        li {
          display: none;
        }
      }

      div.select {
        border-color: var(--response-selected-color);
        background-color: var(--response-selected-color);
        color: white;
      }
    }
  }

  .cesInput {
    color: var(--response-color);
    display: grid;
    font-family: var(--font-family-text);
    font-size: 16px;
    padding: 0px 10px 0px 10px;
    justify-content: var(--questions-general-alignment, center);

    >div {
      text-align: left;
    }

    >.select {
      color: var(--response-selected-color);
    }

    >div {
      >i {
        margin-right: 3px;
      }
    }

    &.box {
      display: grid;
      width: 100%;
      // width: 20rem;
      margin: auto;
      padding: 0px;
      justify-content: var(--questions-general-alignment, center);

      div {
        text-align: center;
        padding: 5px 0px 5px 0px;
        margin: 5px;
        border: var(--response-color) 1px solid;
        border-radius: 5px;
        width: 20rem;

        li {
          display: none;
        }
      }

      div.select {
        border-color: var(--response-selected-color);
        background-color: var(--response-selected-color);
        color: white;
      }
    }
  }

  .PrivateNotchedOutline-legendLabelled-11 {
    width: auto;
    height: 11px;
    display: block;
    padding-left: 50px;
    padding-right: 50px;
    font-size: 0.75em;
    max-width: 0.01px;
    text-align: left;
    transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    visibility: hidden;
  }

  .csatInput {
    color: var(--response-color);
    //padding-left: 43px;
    display: grid;
    // align-items: center;
    justify-content: var(--questions-general-alignment, center);
    padding: 0px 10px 0px 10px;
    font-family: var(--font-family-text);
    font-size: 16px;
    // line-height: 16px;

    >div {
      text-align: left;
    }

    >.select {
      color: var(--response-selected-color);
    }

    >div {
      >i {
        margin-right: 3px;
      }
    }

    &.box {
      display: block;
      width: 20rem;
      margin: auto;
      padding: 0px;

      div {
        text-align: center;
        padding: 5px 0px 5px 0px;
        margin: 5px;
        border: var(--response-color) 1px solid;
        border-radius: 5px;

        li {
          display: none;
        }
      }

      div.select {
        border-color: var(--response-selected-color);
        background-color: var(--response-selected-color);
        color: white;
      }
    }
  }

  .preguntaAgrupadaInputStyle {
    color: var(--response-color);
    list-style: none;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family-text);
    font-size: 16px;
    height: 100%;

    >div {
      text-align: left;
    }

    >.select {
      color: var(--response-selected-color);
    }


    margin: auto;
    cursor: pointer;
    // border:red 1px solid;

    img {
      width: 1.5em;
    }

    >div.select {
      width: 20px;
      height: 20px;
      background: var(--response-selected-color);
      color: var(--response-selected-color);
      border-radius: 100%;
      /* border: 1px solid #ddd; */
      border-style: double;
      border-color: white;
      transition: linear 0.4s;
    }

    >div.sinselect {
      width: 20px;
      height: 20px;
      color: white;
      border: 1px solid var(--response-color);
      border-radius: 100%;
    }

    >div.estadoInicial {
      width: 20px;
      height: 20px;
      border: 1px solid var(--response-color);
      border-radius: 100%;
    }

  }

  .ant-table-measure-row{
    display: none !important;
  }

  ::-webkit-scrollbar {
    height: 0px;
  }

  .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0px !important;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: white !important;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 0px!important;
    padding:10px!important;
    padding-left:5px !important;
    padding-right: 5px!important;
  }

  .ant-table-thead > tr > th {
    border-bottom: 0px!important;
    text-align: center;
    padding:10px!important;
    padding-left:5px !important;
    padding-right: 5px!important;
  }

  .numericStyle {
    color: var(--response-color);
    list-style: none;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family-text);
    font-size: 16px;

    >.select {
      color: var(--response-selected-color);
    }

    >.select-bordered {
      color: white;
      background-color: var(--response-selected-color);
      // border:none;
    }

    li {
      // margin: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      padding: 5px 5px 5px;

      img {
        width: 1.5em;
      }

      div.select {
        width: 20px;
        height: 20px;
        background: var(--response-selected-color);
        color: var(--response-selected-color);
        border-radius: 100%;
        /* border: 1px solid #ddd; */
        border-style: double;
        border-color: white;
        transition: linear 0.4s;
      }

      div.sinselect {
        width: 20px;
        height: 20px;
        color: white;
        border: 1px solid var(--response-color);
        border-radius: 100%;
      }

      div.estadoInicial {
        width: 20px;
        height: 20px;
        border: 1px solid var(--response-color);
        border-radius: 100%;
      }
    }
  }

  .question_agrupada {
    display: flex;

    >.inputOptions {
      width: 60%;

      &.csat {
        width: 70%;
      }

      &.ces {
        width: 70%;
      }
    }
  }

  .titleQuestionAgrupada {
    text-align: left;
    padding-left: 10px;
  }

  .question_agrupada .pregunta {
    padding-left: 13px;
    padding-right: 13px;
    color: var(--response-color);
    font-size: 11px;
    text-align: left;
    width: 40%;
    font-family: var(--font-family-text);
    justify-content: left;
    white-space: pre-line;

    &.csat {
      width: 30%;
    }

    &.ces {
      width: 30%;
    }
  }

  .question-description {
    margin-top: 20px;
    margin-bottom: -15px;
    font-size: 22px;
    font-family: var(--font-family-text);
    color: var(--question-description-color, var(--question-color));
    white-space: pre-line;
    padding: 0px 10px 0px 10px;
    justify-self: var(--questions-general-alignment, center);
    text-align: var(--questions-general-alignment, center);
  }

  .label-score {
    display: flex;
    width: 100%;
    font-family: var(--font-family-text);
    color: var(--response-color);

    >.description-score-worst {
      width: 50%;
      text-align: left;
      white-space: pre-line;
    }

    >.description-score-best {
      width: 50%;
      text-align: right;
      white-space: pre-line;
    }
  }

  .numeric-layout-container {
    margin: auto;
  }

  .topRow {
    width: 100%;
    margin-top: 5px;

    .inputIcons {
      float: right;
      width: 60%;
      display: flex;
      justify-content: center;

      &.csat {
        width: 70%;
      }

      &.ces {
        width: 70%;
      }

      div {
        margin: auto;
      }
    }

    .inputLabels {
      list-style: none;
      padding-left: 0px;
      float: right;
      width: 70%;
      display: table;

      &.motives {
        width: 60%;
        display: flex;
        margin-bottom: 0px;

        >li {
          height: max-content;
          max-width: 50px;
          padding: 0px;
        }
      }

      ;

      >li {
        // border:black 1px solid;
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        width: 50px;
        height: 50px;
        margin: auto;
        font-size: 9.9px;
        font-weight: 500;
      }
    }
  }

  button.btn.shadowed {
    border: 0;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.4);
    color: black;
    background-color: white;
    padding: 10px;
    font-size: 16px;
    display: flex;
    outline: none;

    &[disabled] {
      background-color: #cacaca;
      opacity: 0.6;
    }

    >i {
      display: flex;
    }
  }

  .flex-container {
    background-color: var(--container-background-color);
    display: flex;
    flex-direction: column;
    justify-content: var(--flex-center);

    >.row,
    >.row>.col,
    >.row>.col>.container {
      display: flex;
      flex-direction: column;
      flex-grow: var(--flex-grow);
      width: 100%;
    }
  }

  .spinner {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 75px;
    background-image: url(../gifcaritas2.gif);
  }

  // send button
  // .send-button-container {
  //   justify-content: center;
  // }
  .user-data-input {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
  }

  // arrows
  button.btn.shadowed {
    color: var(--arrow-icon-color);
    background-color: var(--arrow-background-color);
    box-shadow: var(--arrow-box-shadow);
  }

  .presentation-title {
    color: var(--question-color);
    font-family: var(--font-family-questions);
  }

  .presentation-subtitle {
    color: var(--response-color);
    font-family: var(--font-family-text);
  }

  .presentation-text {
    color: var(--response-color);
    font-family: var(--font-family-text);
    text-align: var(--questions-general-alignment, center);
  }

  .attachment-button-container {
    display: flex;
    justify-content: var(--questions-general-alignment, center);
    padding-left: 10px;
    padding-right: 10px;
  }

  .gray-disable {
    filter: grayscale(100%);
  }

  .title-long-matrix {
    margin-bottom: 0px;
    margin-top: 30px;
  }

  .container-long-matrix {
    margin-bottom: 0px;
  }

  @media (max-width: 564px) {
    .matrix-table td {
      padding: 5px !important;
    }
  }

  @media (max-width: 450px) {
    .matrix-table .less-padding {
      padding: 3px !important;
    }
    .matrix-table td {
      padding: 5px !important;
    }
  }

  @media screen and (max-width: 420px) {
    :local(.caritaStyle) li {
      float: left;
      margin-left: 2px;
      text-align: center;
    }

    :local(h3) {
      display: block;
      text-align: center;
      font-size: 18px;
    }

    .motivo {
      margin-left: 0px !important;
      margin-bottom: 5px;
      display: block !important;
    }
  }

  .smileContainer {
    width: 5rem;
  }
  @media (max-width: 410px) {
    .smileContainer {
      width: 3rem;
    }
    .caritaStyle.count-5>li img {
      width: 3.5em !important;
    }
  }

  .motive-disabled {
    cursor: default;
    opacity: 0.5;
  }

  .matrix-table-container {
    max-width: 100%;
    overflow-x: auto;
    scrollbar-width: thin;
  }
}

@media (max-width: 310px) {
  .numeric-layout-container {
    width: 90%;
  }
}

.inside .pregunta {
  margin-bottom: 30px;
}