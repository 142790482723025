.smile-spinner {
    border: solid;
    border-color: transparent;
    border-top-color: currentColor;
    border-radius: 50%;
    width: 35px;
    height: 40px;
    border-width: 2px;
    border-top-color: #00aae4;
    animation: spin 1s infinite linear;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}