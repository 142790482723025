.numeric-border {
    border: solid 1px;
    border-left-width: 0.5px;
    border-right-width: 0.5px;
    border-radius: 0px 0px 0px 0px;
    border-color: #595959;
}

.numeric-border-color {
    border-color: #ffffff;
}