.app-container.web {
  
  .container-fluid {
    // max-width: 396px;
    // min-height: 700px;
    box-shadow: var(--container-shadow);
    min-height: var(--container-min-height);
    
    min-width: var(--container-min-width);
    
    width:var(--container-width);

  }


  @media screen and (max-height:700px) {

    .container-fluid {
      
      min-height: 600px;
        
    }
    
  }

  @media screen and (max-height:600px) {

    .container-fluid {
      
      min-height: unset;
        
    }
    
  }
}
