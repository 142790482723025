.app-container.playground {
  
  .container-fluid {
    // max-width: 396px;
    // min-height: 700px;
    box-shadow: var(--container-shadow);
    min-height: var(--container-min-height);
    
    min-width: var(--container-min-width);
    
    width:var(--container-width);
    --container-shadow: none;

  }
}
