
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.container{
  >.inside{
    >.userDataForm{
      padding-left: 10px;
      padding-right: 10px;
      color:var(--response-color);
      white-space: pre-wrap;
      font-family: var(--font-family-text);
      width: 100%;
      >.title{
        font-family: var(--font-family-questions);
        color:var(--question-color);
        font-size: 20px;
        font-weight: 500;
        text-align: var(--questions-general-alignment, center);
      }
      >.subtitle{
        font-size: 17px;
        text-align: var(--questions-general-alignment, center);
      }

      >.footer{
        font-size:17px;
        text-align: var(--questions-general-alignment, center);
      }

      .field{
        display: flex;
        // justify-content: center;
        margin: auto;
        margin-bottom: 10px;
        width: 20rem;
        line-height: 30px;
        font-size: 17px;
        color:black;
        >input{
          background-color: white;
          outline:none;
          border:1px solid;
          border-radius: 10px;
          border-color:var(--response-selected-color);
          padding-left: 8px;
          width: 100%;
        }
        >select{
          outline:none;
          border:1px solid;
          border-radius: 10px;
          border-color:var(--response-selected-color);
          padding-left: 5px;

        }

        // FOR MATERIAL UI

          >.MuiFormControl-marginDense {
            margin-top: 0px;
            margin-bottom: 0px;
          }

          >.MuiFormControl-root {
            width: 100%;
            >.MuiFormLabel-root {
              color:var(--response-color);
              font-family: var(--font-family-text);
              padding-right: 15px;
              font-size: small;
            }
          }

          .MuiOutlinedInput-notchedOutline{
            border-color:var(--response-selected-color);
            // background-color: white;
          }
          .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
            border-color: #f44336 !important;
          }
          .MuiFormLabel-root.Mui-error {
            color: #f44336 !important;
          }

          .MuiFormHelperText-root {
            color: red;
          }

          .MuiOutlinedInput-root{
            background-color:var(--background-color);
            border-radius: 10px;
            color:var(--response-color);
          }

          #date-picker-dialog{
            color:black;
            font-family: var(--font-family-text);
          }

        // FOR ANT DESIGN SELECT
        >.ant-select{
          >.ant-select-selector{
            background-color: white;
            border-color: var(--response-selected-color);
            border-radius: 10px;
            color:black;
            font-size: 17px;
            text-align: left;
            >.ant-select-selection-placeholder{
              color:#757575 !important;
              opacity: 1;
            }
          }
        }
        >.ant-picker{
          border-color: var(--response-selected-color);
          border-radius: 10px;
          >.ant-picker-input{
            >input{
              color:black;
              font-size: 17px;
            }
          }

          ::placeholder {
            color: #757575;
          }
        }
      }
    }
    .userDataForm-container{
      display: grid;
      justify-content: var(--questions-general-alignment, center);
    }
  }
}

label.MuiInputLabel-shrink + div > fieldset > legend {
  padding-right: 30px !important;
}


// for modal datepicker dialog
.MuiPickersToolbar-toolbar  {
  background-color: var(--question-color);
}

.MuiPickersDay-current{
  color: var(--question-color);
}

.MuiButton-textPrimary{
  color: var(--question-color);
}

.MuiTypography-colorPrimary{
  color: var(--question-color);
}
.MuiPickersDay-daySelected{
  background-color: #4bc3b4 !important;
  &.MuiPickersDay-current{
    color: white !important;
  }
}

.MuiFormLabel-filled{
  color:var(--response-selected-color) !important;
}
.MuiSvgIcon-root{
  color:var(--response-selected-color) !important;
}