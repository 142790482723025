.app-container.tablet {

  --container-min-height: 0px;
  
  .pregunta {
    font-size: 4vw;
  }
  .caritaStyle {
    height: 17vw;
  }

  .caritaStyle li img {
    width: 14vw;
  }
  .caritaStyle li img.select {
    width: 17vw;
  }

  .preguntaComentario {
    font-size: 3vw;
  }

  .motivo-checklist {
    font-size: 2.5vw;
    line-height: normal;
  }

  .flex-container {
    width: 100%;
  }

  .cesInput > div {
    font-size: 3vw;
  }

  .csatInput > div {
    font-size: 3vw;
  }

  .numericStyle li {
    font-size: 3.5vw;
  }

  .smiles {
    font-size: 2.5vw !important;
  }

  .motivo {
    font-size: 3.5vw;
    min-height: 9vw;
    min-width: 23vw;
  }

  .boton {
    font-size: 3vw;
    min-width: 21vw;
    min-height: 7vw;
  }

  button.btn.shadowed {
    height: 6vw;
    width: 6vw;
    padding: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .anticon {
    font-size: 3vw;
  }
  .motivo-box {
    font-size: 3vw;
    height: 5.5vw;
    vertical-align: center;
    align-items: center;
  }

  .csatInput {
    justify-content: center;
    margin-top: 4vw;
    margin-bottom: 4vw;
  }

  a {
    pointer-events: none;
  }

  // @media screen and (max-height:800px) {

  //   .pregunta {
  //     font-size: 4vw !important;
  //   }

  // }

  // @media screen and (max-height:600px) {

  //   .container-fluid {

  //     min-height: unset;

  //   }

  // }
}
