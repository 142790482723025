.smile-alert-container {
    border: 1px solid;
    border-radius: 3px;
    padding: 10px;
    display: flex;
    align-items: center;
    margin: 5px;
}

.alert-icon {
    padding-right: 5px;
    display: flex;
}