
.thanks-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.thanks-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: var(--marginBottomThanksBody);
  flex-grow: 1;
  padding:0px 10px 0px 10px;

}


.logo-container {
  margin: 10px 0;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  align-items: center;
}

.cursor-removed {
  .Typewriter__cursor {
    display: none
  }
}

.thanks-view .pregunta {
  align-items: center;
  justify-content: center;
}

.thanks-view .logo-container {
  align-items: center;
  justify-content: center;
}