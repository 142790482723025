.smile-rate-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
}

.smile-rate-icon {
    cursor: pointer;
    height: 2em;
    font-size: 30px;
    transition: 0.2s;
    width: 1.2em;
}

.smile-rate-icon:hover {
    transform: scale(1.2);
    transition: 0.2s;
}